import React, { useEffect, useState } from 'react';
import { Button, Box } from '@mui/material';
import './specifyNeeds.css';
import {Langage} from "../../language";
import {appLanguage} from "../../select";


const SpecifyNeeds = (props) => {

    const { needs, arg, analysis, state } = props
    const [myNeeds, setMyNeeds] = useState([]);

    const getText = (key) => {
        return Langage[key][appLanguage]
    }

    const addNeed = async (item) => {
        if(myNeeds.some( i => i.key === item.key)) {
            setMyNeeds(myNeeds.filter(i => i.key !== item.key));
        } else {
            if(myNeeds.length < 3) {
                setMyNeeds( arr => [...arr, item]);
            }
        }
    }

    const confirm = async () => {
        new Promise((resolve, reject) => {
            props.setState((prev) => {
                resolve(true);
                return {...prev, ...{myNeeds}}
            })
        }).then(r => {
            props.actionProvider.next(33, {myNeeds});
        })

    }

    useEffect(() => {
        setTimeout(() => {
            document.querySelector('#result-t').scrollIntoView();
        }, 1000)
        state.needs.forEach((n, index) => {
            if(index < 3) {
                addNeed(n);
            }
        })
    }, [state.needs])


    return (<div>
        <span className={'result-title'} id={'result-t'}>{getText('my_result')}</span>
        <Box  className={'result-treat'}>
            {
                state.needs && state.needs.map((item) => {
                    return (
                        <div className={'check-con'} key={item.key} onClick={ () => {addNeed(item)}}>
                            <img src={'/assets/images/check'+ (myNeeds.some( i => i.key === item.key) ? 'ed' : '') +'.png'} alt="pub"/>
                            <Button variant="outlined">
                                {getText(item.key.toLowerCase().replace('regulation', 'brillance'))}
                            </Button>
                           {/*  <div className={'bg'}>
                                <div> <div style={{"backgroundSize": (item.final *100)+'%', "backgroundImage": `linear-gradient(to left, #E8CB65, #A6D866 ${(item.final *200)+'%'})`}}></div> </div>
                            </div> */}
                        </div>
                    )
                })
            }
        </Box>
        <Button variant="outlined" onClick={ () => {confirm()}}>{getText('confirm')}</Button>
    </div>)
}

export default SpecifyNeeds
