import './App.css';
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import config from './bot/config.js';
import MessageParser from './bot/MessageParser.js';
import ActionProvider from './bot/ActionProvider.js';
import {uploadPictureD_id} from "./api/service";
import {titleNormalized} from "./select";

function App() {

    const getAppName = (app) => {
        switch (app) {
            case 'chatbot-hair' : return 'SALON DE COIFFURE';
            case 'newpharma' : return 'NEW PHARMA';
            case 'jess' : return 'jessica simpson';
            default : return 'Cactus';
        }
    }

    const postPicture = async (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                try {
                    const result = await uploadPictureD_id(file);
                    if(result.result_url) {
                        document.getElementById('ai-conf').style.display = 'none';
                    }
                }
                catch (e) {

                }
            }
        }
    }

  return (
    <div className="App">
      <Chatbot
        config={config}
        headerText={getAppName(titleNormalized)}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        placeholderText=' '
        validator={(msg) =>  msg.length > 0}
      />
        {/*<div id={"ai-con"}>*/}
            {/*<img src="/assets/images/config.png" className={'setting'} onClick={ (e) => {document.getElementById('ai-conf').style.display = 'block'}}/>*/}
            {/*<video src={localStorage.getItem('MY_AI_TALKING_FACE') ? localStorage.getItem('MY_AI_TALKING_FACE') : "/assets/video1.mp4"} autoPlay={true} loop={true} id={"ai-video"}></video>*/}
            {/*<div id={'ai-conf'}>*/}
            {/*    <input accept="image/*"*/}
            {/*           type="file"*/}
            {/*           capture="environment"*/}
            {/*           onChange={ (e) => {postPicture(e.target, 25)}} />*/}
            {/*</div>*/}
        {/*</div>*/}
    </div>
  );
}

export default App;
