import React, { } from 'react'
import { Button, Box } from '@mui/material';
import './multiChoices.css';
import { saveResWidget } from '../../utils/utils';


const MultiChoices = (props) => {
    const { setState, payload, actionProvider } = props
    const {choices, id} = payload;

 

    // const form = props.children.props.state.messages.filter(f=> f.type === "widget");
    // console.log(form[form.length-1])

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            {
                choices.map((item, index) =>
                    <Button key={index} variant="outlined" onClick={ () => {saveResWidget((item.value || item.text || item), setState); actionProvider.next(item.next || id+1)} }>
                        { item.img && <img src={"/assets/images/hair_icons/" + item.img + ".png"} width={'42'} /> }
                        {(item.text || item)}
                    </Button>
                )
            }
        </Box>
    )
}

export default MultiChoices
