import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import './choices.css';
import { saveResWidget } from '../../utils/utils';

const Choices = (props) => {
    const { payload, actionProvider, setState } = props;
    const { id, choices } = payload;

    const [inputValue, setInputValue] = useState('');

    const button = document.getElementsByClassName("react-chatbot-kit-chat-btn-send")[0];
    const input = document.getElementsByClassName("react-chatbot-kit-chat-input")[0];

    const handleEvent = () => {
        console.log('entre');
        button.removeEventListener("click", clickHandler);
        input.removeEventListener("keydown", keydownHandler);
        actionProvider.next(id + 1);
        saveResWidget(inputValue, setState);
    };

    const handleChoice = (item) => {
        if(!inputValue.includes((item.text || item))) {
            setInputValue((prevValue) =>
                prevValue ? prevValue + ' et ' + (item.text || item) : (item.text || item)
            )
        }

    };

    const handleButtonClick = () => {
        handleEvent();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleEvent();
        }
    };

    const clickHandler = () => {
        handleButtonClick();
    };

    const keydownHandler = (event) => {
        handleKeyDown(event);
    };

    useEffect(() => {
        input.value = inputValue
    }, [inputValue]); // The empty dependency array ensures this effect runs only once on mount

    // Attach event listeners when the component is mounted
    useEffect(() => {
        

       

        button.addEventListener("click", clickHandler);
        input.addEventListener("keydown", keydownHandler);

        // Clean up by removing event listeners when the component is unmounted
        return () => {
            button.removeEventListener("click", clickHandler);
            input.removeEventListener("keydown", keydownHandler);
        };
    }, []); // The empty dependency array ensures this effect runs only once on mount

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            {choices.map((item, index) => (
                <Button key={index} variant="outlined" onClick={() => handleChoice(item)}>
                    {item.img && <img src={`/assets/images/hair_icons/${item.img}.png`} width={'42'} />}
                    {item.text || item}
                </Button>
            ))}
        </Box>
    );
};

export default Choices;
