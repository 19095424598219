import React from 'react';
import { registerProfile, getToken, findProfileByEmail } from '../api/service';

const MessageParser = ({ children, actions }) => {

  let historyMessage = children.props.state.messages
  const setState = children.props.setState
  const states = children.props.state

  const findLastMsgBot = () => {
    
    let count = historyMessage.length -1
    while(count >= 0){
      if(historyMessage[count].type === 'bot')
        return count
      else count--
    }
  }

  const registerCustomer = async(email) => {

    let customer_id

    const data = {
      age: states.age,
      "app":"abbi",
      email: email,
      firstname : states.fullName.split(' ')[0] || null,
      lastname: states.fullName.split(' ')[1] || states.fullName.split(' ')[0] ,
      group_id: 1,
      pwd: "effimetrix"
    }

    let res = await findProfileByEmail(email)
    if(res.items.length){
      customer_id = res.items[0].id
    }
    else{
      const tmp = await registerProfile(data)
      customer_id = tmp.fk_customer_id
    }

    const token = await getToken(customer_id)
    updateCustomer('token', token.token)
    
  }
  


  const updateCustomer = (key, value) => {
    setState(prevState => ({
      ...prevState,
      [key]: value
    }))
  }
  
  const parse = (message) => {

  // console.log(message)
  const lastIdBot = findLastMsgBot()
  let nextId = historyMessage[lastIdBot].payload?.next
  let currentUid = historyMessage[lastIdBot].payload?.uid



  if(currentUid === 0){
    updateCustomer('fullName', message)
  }
  else if(currentUid === 1){
    updateCustomer('age', message)
  }
  else if(currentUid === 2){
    updateCustomer('genre', message)
  }
  else if(currentUid === 5){
    registerCustomer(message)
    updateCustomer('email', message)
  }
  
  actions.next(nextId)
    
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
