export const Langage = {
    "disable_dictation": {
        "french": "Désactiver la dictée",
        "english": "disable dictation"
    },
    "disable_lecture": {
        "french": "Désactiver la lecture",
        "english": "Disable Text-To-Speech"
    },
    "welcome": {
        "french": "Bienvenue sur Cactus. En utilisant cette plateforme le son doit être activé.",
        "english": "Welcome to Sam Tech. Using this platform sound must be enabled."
    },
    "start": {
        "french": "Salut ! Je suis ton assistant personnel, une IA conçue pour déterminer tes besoins et les produits adaptés. Mais avant, faisons connaissance ! Comment t’appelles-tu ?",
        "english": "Hi! I am your personal assistant, an AI designed to determine your needs and the right products. But first, let's get to know each other! What's your name?"
    },
    "age": {
        "french": "Quel âge as-tu ?",
        "english": "How old are you?"
    },
    "gender": {
        "french": "Es-tu une femme, un homme ou ne souhaites-tu pas te définir ?",
        "english": "Are you a woman, a man, or do you not want to define yourself?"
    },
    "woman": {
        "french": "Femme",
        "english": "Woman"
    },
    "man": {
        "french": "Homme",
        "english": "Man"
    },
    "not_define": {
        "french": "Non-binaire",
        "english": "None"
    },
    "start_experience": {
        "french": "Démarrons l’expérience ! Nous allons analyser tes problématiques de peau grâce à une analyse par IA, un diagnostic validé par des dermatologues.",
        "english": "Let's start the experience! We will analyze your skin problems through an AI analysis, a diagnosis validated by dermatologists."
    },
    "start_experience_hair": {
        "french": "Démarrons l’expérience !",
        "english": "Let's start the experience!"
    },
    "determine": {
        "french": "Nous avons déterminé que votre peau est à",
        "english": "We have determined that your skin is"
    },
    "today_analysis": {
        "french": "D'après le selfie, voici votre peau aujourd'hui :",
        "english": "Based on the selfie, this is your skin today:"
    },
    "i_will_propose": {
        "french": "Je vais te proposer des produits qui répondent aux besoins de ta peau. Clique sur les besoins que tu souhaites prioriser ci-dessous",
        "english": "I will suggest products that meet the needs of your skin. Click on the needs you want to prioritize below"
    },
    "here_your_analysis": {
        "french": "Voici les résultats de votre analyse",
        "english": "Here are the results of your analysis"
    },
    "here_your_presc": {
        "french": "Voici votre prescription",
        "english": "Here is your prescription"
    },
    "continue": {
        "french": "continue 2ème partie",
        "english": "continue part 2"
    },
    "i_choose_type": {
        "french": "Je choisis mon type de peau",
        "english": "I choose my skin type"
    },
    "have_alergy": {
        "french": "As-tu des allergies ?",
        "english": "Do you have allergies?"
    },
    "with_one": {
        "french": "Si oui lesquelles ?",
        "english": "If yes, which ones?"
    },
    "yes": {
        "french": "Oui",
        "english": "Yes"
    },
    "nope": {
        "french": "Non",
        "english": "No"
    },
    "rides_indique": {
        "french": "Peux-tu m'indiquer où sont situées tes rides ?",
        "english": "Can you tell me where your wrinkles are located?"
    },
    "eye_circle": {
        "french": "Autour de l'oeil",
        "english": "around the eye"
    },
    "in_front": {
        "french": "Sur le front",
        "english": "On the forehead"
    },
    "bouche_circle": {
        "french": "Autour de la bouche",
        "english": "around the mouth"
    },
    "rides_sourc": {
        "french": "Entre les sourcils (ride du lion)",
        "english": "Between the eyebrows (frown lines)"
    },
    "partout": {
        "french": "Partout",
        "english": "Everywhere"
    },
    "want_relachement": {
        "french": "Voudrais-tu travailler sur le relâchement cutané ?",
        "english": "Would you like to work on sagging skin?"
    },
    "i_want_relachement": {
        "french": "Je souhaites travailler sur le relâchement cutané",
        "english": "I want to work on sagging skin"
    },
    "eczema_concern": {
        "french": "Es-tu concerné·e par de l'eczéma ou de la rosacée ?",
        "english": "Do you have eczema or rosacea?"
    },
    "i_m_eczema_concern": {
        "french": "Je suis concerné par de l'eczéma ou de la rosacée",
        "english": "I have eczema or rosacea"
    },
    "product_correspondent": {
        "french": "Parmi ces produits, lesquels te correspondent ?",
        "english": "Which of these products are right for you?"
    },
    "requilibrant": {
        "french": "Rééquilibrant",
        "english": "Rebalancing"
    },
    "none_sulfate": {
        "french": "Sans sulfate",
        "english": "Sulfate free"
    },
    "purifiant": {
        "french": "Purifiant",
        "english": "Purifying"
    },
    "matifiant": {
        "french": "Matifiant",
        "english": "Mattifying"
    },
    "boutons_indique": {
        "french": "Peux-tu m'indiquer où sont situés tes boutons ?",
        "english": "Can you tell me where your pimples are located?"
    },
    "zone_t": {
        "french": "Zone T",
        "english": "T Zone"
    },
    "in_joues": {
        "french": "Sur les joues",
        "english": "On the cheeks"
    },
    "locale": {
        "french": "Localisés",
        "english": "Localized"
    },
    "see_boutons": {
        "french": "Ces boutons sont-ils présents ?",
        "english": "Are these pimples present?"
    },
    "permanence": {
        "french": "En permanence",
        "english": "Always"
    },
    "episod": {
        "french": "De manière épisodique",
        "english": "Occasionally"
    },
    "remove_taches": {
        "french": "Quel type de tâches souhaites-tu éliminer / réduire ?",
        "english": "What kind of blemishes do you want to eliminate/reduce?"
    },
    "rouss_taches": {
        "french": "Tâches de rousseur",
        "english": "Freckles"
    },
    "old_taches": {
        "french": "Tâches de vieillesse",
        "english": "Age spots"
    },
    "vin_taches": {
        "french": "Tâches de vin",
        "english": "Wine stains"
    },
    "birth_taches": {
        "french": "Tâches de naissance",
        "english": "Birthmarks"
    },
    "pregnant_taches": {
        "french": "Tâches de grossesse",
        "english": "Pregnancy stains"
    },
    "hydrate": {
        "french": "Quelles sont les manifestations de la déshydratation cutanée ?",
        "english": "What are the signs of skin dehydration?"
    },
    "teint_terne": {
        "french": "Teint terne",
        "english": "Dull complexion"
    },
    "stries": {
        "french": "Stries",
        "english": "Stretch marks"
    },
    "souplesse": {
        "french": "Manque de souplesse",
        "english": "Lack of skin suppleness"
    },
    "traillements": {
        "french": "Tiraillements",
        "english": "Skin tightness"
    },
    "demangeaisons": {
        "french": "Démangeaisons",
        "english": "Itching"
    },
    "visible_pores": {
        "french": "Tes pores sont-ils toujours visibles ou seulement à certains moments de la journée ? ",
        "english": "Are your pores always visible or only at certain times of the day?"
    },
    "never": {
        "french": "Jamais",
        "english": "Never"
    },
    "some_time": {
        "french": "Parfois",
        "english": "Sometimes"
    },
    "always": {
        "french": "Toujours",
        "english": "Always"
    },
    "day_period": {
        "french": "Dépendant du moment de la journée",
        "english": "Depending on the time of day"
    },
    "pores_light": {
        "french": "Pores légèrement apparents",
        "english": "Slightly visible pores"
    },
    "pores_exposed": {
        "french": "Pores apparents",
        "english": "Visible pores"
    },
    "pores_sometimes": {
        "french": "Pores parfois apparents",
        "english": "Sometimes visible pores"
    },
    "cicatrice_on_face": {
        "french": "As-tu des cicatrices sur le visage ? ",
        "english": "Do you have scars on your face?"
    },
    "none": {
        "french": "Aucune",
        "english": "None"
    },
    "some": {
        "french": "Quelques-unes",
        "english": "Some"
    }
    ,
    "a_lot": {
        "french": "Beaucoup",
        "english": "A lot"
    },
    "pores_scars": {
        "french": "J'ai des cicatrices sur le visage",
        "english": "I have scars on my face"
    },
    "pores_scars_alot": {
        "french": "J'ai beaucoup de cicatrices sur le visage",
        "english": "I have a lot of scars on my face"
    },
    "pollue_city": {
        "french": "Vis-tu dans un environnement pollué ?",
        "english": "Do you live in a polluted environment?"
    },
    "pollue_a_lot": {
        "french": "Très pollué",
        "english": "Very polluted"
    },
    "pollue_average": {
        "french": "Moyennement pollué",
        "english": "Moderately polluted"
    },
    "pollue_little": {
        "french": "Peu pollué",
        "english": "Little polluted"
    },
    "none_pollue": {
        "french": "Pas du tout pollué",
        "english": "Not polluted at all"
    },
    "product_interess": {
        "french": "Es-tu intéressé·e par des produits autobronzants ou plutôt par des produits \"coup d'éclat\"",
        "english": "Are you interested in self-tanning products or rather in \"radiance boost\" products?"
    },
    "eclat_coup": {
        "french": "Coup d'éclat",
        "english": "Radiance boost"
    },
    "autbronzant": {
        "french": "Auto-bronzant",
        "english": "Self-tanning"
    },
    "desire_product": {
        "french": "Comment désires-tu ton produit ?",
        "english": "How do you want your product?"
    },
    "vegan": {
        "french": "Vegan",
        "english": "Vegan"
    },
    "bio": {
        "french": "Bio",
        "english": "Organic"
    },
    "natural": {
        "french": "D'origine naturelle",
        "english": "Natural origin"
    },
    "made_in_france": {
        "french": "Fabriqué en France",
        "english": "Made in France"
    },
    "renseigne_email": {
        "french": "Ta prescription de produits personnalisés va être élaborée, renseignes ton email pour la reçevoir.",
        "english": "Your personalized product prescription will be elaborated, fill in your email to receive it."
    },
    "your_products": {
        "french": "Tu ne trouveras dans cette page que les produits qui te correspondent.\nLa peau évolue tous les jours, c’est pour ça que c’est important de lui apporter les actifs essentiels dont elle a besoin à chaque moment.",
        "english": "You will only find on this page the products that suit you. The skin evolves every day, that's why it's important to provide it with the essential active ingredients it needs at all times."
    },
    "take_selfie": {
        "french": "Prendre mon selfie",
        "english": "Take my selfie"
    },
    "take_selfie_hair": {
        "french": "Prendre la photo globale de ma chevelure",
        "english": "Please take a selfie of your hair"
    },
    "take_selfie_scalp": {
        "french": "Prendre une photo de mon cuir chevelu",
        "english": "Please take a selfie of your scalp"
    },
    "my_analysis": {
        "french": "Analyse de la peau en cours",
        "english": "Skin analysis in progress"
    },
    "process_analysis": {
        "french": "Analyse en cours :",
        "english": "Analysis in progress"
    },
    "rides": {
        "french": "Rides",
        "english": "Wrinkles"
    },
    "taches": {
        "french": "Taches",
        "english": "Stains"
    },
    "imperfections": {
        "french": "Imperfections",
        "english": "Imperfections"
    },
    "sensibilite": {
        "french": "Sensibilité",
        "english": "Sensitivity"
    },
    "hydratation": {
        "french": "Hydratation",
        "english": "Hydration"
    },
    "brillance": {
        "french": "Brillance",
        "english": "Shine"
    },
    "eclat": {
        "french": "Éclat",
        "english": "Glow"
    },
    "pores": {
        "french": "Grain de peau",
        "english": "Skin texture"
    },
    "see_result": {
        "french": "Voir les résultats détaillés",
        "english": "See detailed results"
    },
    "ok": {
        "french": "OK",
        "english": "OK"
    },
    "i_accept": {
        "french": "J'accepte",
        "english": "I Accept"
    },
    "my_result": {
        "french": "Mes résultats",
        "english": "My results"
    },
    "confirm": {
        "french": "Confirmer",
        "english": "Confirm"
    },
    "presc_on_way": {
        "french": "Élaboration de la \n prescription en cours",
        "english": "Prescription in progress"
    },
    "see_our_offer": {
        "french": "En attendant ta \n prescription, découvre \n notre offre exclusive.",
        "english": "While waiting for your \n prescription, discover our exclusive offer."
    },
    "i_have_products": {
        "french": "Super, j’ai la sélection de \n  produits qu’il te faut !",
        "english": "Great, I've got the selection of products you need!"
    },
    "see_ma_selection": {
        "french": "Voir ma sélection",
        "english": "See my selection"
    },
    "here_your_result": {
        "french": "Voici les résultats de l'analyse de peau",
        "english": "Here are the results of the skin analysis"
    },
    "close": {
        "french": "Fermer",
        "english": "Close"
    },
    "age_q": {
        "french": "Âge :",
        "english": "Age:"
    },
    "gender_q": {
        "french": "Genre :",
        "english": "Gender:"
    },
    "today_type": {
        "french": "Voici ton type de peau aujourd'hui :",
        "english": "Here's your skin type today:"
    },
    "brief_allergie": {
        "french": "Allergies :",
        "english": "Allergies:"
    },
    "brief_rides": {
        "french": "Mes rides se situent ",
        "english": "My wrinkles are located "
    },
    "brief_product_correspondent": {
        "french": "Pour éviter la brillance, je recherche un produit ",
        "english": "To avoid shine, I am looking for a product "
    },
    "ask_question": {
        "french": "Nous sommes en train d’analyser tes cheveux , nous allons compléter l’analyse avec quelques questions.",
        "english": "We are currently analyzing your hair, and will complete the analysis with a few questions."
    },
    "brief_boutons_found": {
        "french": "Mes boutons se trouvent ",
        "english": "My pimples are located "
    },
    "brief_boutons_present": {
        "french": "Mes boutons sont présents ",
        "english": "My pimples are present "
    },
    "brief_taches": {
        "french": "Je souhaite réduire les ",
        "english": "I want to reduce the "
    },
    "brief_hydrate": {
        "french": "Déshydratation : ",
        "english": "Dehydration: "
    },
    "brief_eclat_env": {
        "french": "Environnement : ",
        "english": "Environment: "
    },
    "brief_eclat": {
        "french": "Pour l'éclat de mon visage je recherche un produit ",
        "english": "For the radiance of my face I am looking for a product "
    },
    "brief_desire_product": {
        "french": "Je souhaite que mon produit soit : ",
        "english": "I want my product to be: "
    },
    "SECHE": {
        "french": `<b>L'analyse est terminée, j'ai fait une étude complète de ton profil :</b>
    <br> Ta peau
    présente des stries, un grain de peau serré et un teint terne, qui sont les signes d'une
    peau à tendance sèche.<br><br>
    <b>Mon conseil :</b><br> élimine régulièrement les cellules mortes par un nettoyant non
    agressif, préfère une huile démaquillante à une eau micellaire, nourris
    régulièrement ta peau avec des masques adaptés`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile:</b>
     <br> Your skin
     has streaks, tight skin texture and a dull complexion, which are signs of a
     dry skin.<br><br>
     <b>My advice:</b><br> Regularly remove dead cells with a cleanser not
     aggressive, prefers a cleansing oil to a micellar water, nourishes
     your skin regularly with suitable masks`
    },
    "GRASSE": {
        "french": `<b>L'analyse est terminée, j'ai fait une étude complète de ton profil :</b>
    <br>Ta peau présente des
    pores irréguliers, dilatés avec la présence de points noirs, de boutons, un excès de
    brillance, ce sont les signes d'une peau grasse<br><br>
    <b>Mon conseil :</b> <br>nettoie ta peau régulièrement avec des produits doux, sans alcool et
    sans savon, hydrate la peau , utilise des masques purifiants, évite les produits à base
    d'huile`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile:</b>
     <br>Your skin has
     irregular, dilated pores with the presence of blackheads, pimples, excess
     shine, these are the signs of oily skin<br><br>
     <b>My advice:</b> <br>cleanse your skin regularly with gentle, alcohol-free products
     soap-free, hydrates the skin, uses purifying masks, avoids products containing
     of oil`
    },
    "DESHYDRATEE": {
        "french": `<b>L'analyse est terminée, j'ai fait une étude complète de ton profil : </b>
    <br>Ta peau présente
    de fines ridules, l'éclat dû teint est légèrement terne, le grain de peau est serré, la
    sensibilité est présente et la peau nécessite un fort besoin d'hydratation, ce sont les
    signes d'une peau déshydratée<br><br>
    <b>Mon conseil :</b> protége ta peau du froid, du soleil et du vent, évite les produits
    détergents, évite la présence de climatisation, privilégie des produits riches en
    lipides`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile: </b>
     <br>Your skin presents
     fine lines, the radiance of the complexion is slightly dull, the skin texture is tight, the
     sensitivity is present and the skin requires a strong need for hydration, these are the
     signs of dehydrated skin<br><br>
     <b>My advice:</b> protect your skin from the cold, the sun and the wind, avoid products
     detergents, avoid the presence of air conditioning, favor products rich in
     lipids`
    },
    "MATURE": {
        "french": `<b>L'analyse est terminée, j'ai fait une étude complète de ton profil :</b>
    <br>Ta peau révèle
    des rides sur les zones d'expression, le teint est terne, on note des stries de
    déshydratation, ce sont les signes d'une peau Mature
    <b>Mon conseil :</b><br><br>utilise des gommages doux 2 fois par semaine, hydrate ta peau
    chaque jour , utilise un soin anti-rides le jour et utilise un sérum anti-âge et une
    crème repulpante le soir, protège ta peau du soleil et de la pollution`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile:</b>
     <br>Your skin reveals
     wrinkles on the expression areas, the complexion is dull, there are streaks of
     dehydration, these are the signs of mature skin
     <b>My advice:</b><br><br>use gentle scrubs twice a week, moisturize your skin
     every day, use an anti-wrinkle day care and use an anti-aging serum and a
     plumping cream in the evening, protect your skin from the sun and pollution`
    },
    "SENSIBLE": {
        "french": `<b>L'analyse est terminée, j'ai fait une étude complète de ton profil :</b> 
    <br>le type de peau qui
    te correspond est une peau sensible . une peau sensible a une tendance à la
    réactivité aux produits cosmétiques avec du parfum, des conservateurs, des tensio-
    actifs
    <b>Mon conseil :</b><br><br>Évite les gommages agressifs, utilise un écran total, évite le tabac,
    l'alcool, protège-toi des pollutions, de la climatisation , , sèche ton visage sans
    frotter mais en tamponnant`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile:</b>
     <br>the type of skin that
     corresponds to you is a sensitive skin. sensitive skin has a tendency to
     reactivity to cosmetic products with perfume, preservatives, surfactants
     <b>My advice:</b><br><br>Avoid harsh scrubs, use sunblock, avoid tobacco,
     alcohol, protect yourself from pollution, air conditioning, dry your face without
     rubbing but dabbing`
    },
    "ACNEIQUE": {
        "french": `<b>L'analyse est terminée, j'ai fait une étude complète de ton profil :</b>
    <br>Ta peau présente
    de nombreux boutons, des points noirs, les pores sont dilatés, la brillance est
    excessive, ce sont les signes d'une peau Acnéique
    Mon conseil :<br><br> Hydrate régulièrement ta peau, nettoie ta peau matin et soir avec des
    produits doux , sans savon, utilise des crèmes matifiantes pour masquer les défauts`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile:</b>
     <br>Your skin presents
     many pimples, blackheads, pores are enlarged, shine is
     excessive, these are the signs of acne-prone skin
     My advice: <br><br> Moisturize your skin regularly, cleanse your skin morning and evening with
     gentle products, without soap, use mattifying creams to conceal blemishes`
    },
    "EQUILIBREE": {
        "french": `<b>L'analyse est terminée, j'ai fait une étude complète de ton profil :</b> 
    <br>Ta peau présente
    de bons scores sur tous les critères étudiés : l'éclat du teint est bon, le grain de peau
    lisse, l'hydratation parfaite, très peu de sensibilité et de brillance, ce sont les signes
    d'une peau équilibrée
    Mon conseil :<br><br> nettoie régulièrement ta peau, hydrate-la tous les jours, exfolie les
    peaux mortes 1 à 2 fois par semaine, protège ta peau du soleil`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile:</b>
     <br>Your skin presents
     good scores on all the criteria studied: the radiance of the complexion is good, the skin texture
     smooth, perfect hydration, very little sensitivity and shine, these are the signs
     of balanced skin
     My advice: <br><br> regularly cleanse your skin, moisturize it daily, exfoliate the
     dead skin 1 to 2 times a week, protect your skin from the sun`
    },
    "MIXTE": {
        "french": `<b>L'analyse est terminée, j'ai fait une étude complète de ton profil :</b> 
    <br>Ta peau présente
    des points noirs sur la zone T, les pores sont dilatés sur la zone T et sont serrés sur le
    reste du visage, tu ressens des tiraillements localisés , le teint est terne sur les joues
    et brillant sur la zone T , ce sont les signes d'une peau mixte
    <b>Mon conseil :</b><br><br>il est important de contrôler l'excès de sébum des zones grasses sans
    dessécher davantage les zones plus sèches. Il est donc conseillé de privilégier des
    produits non gras et d'éviter tous les produits pouvant contenir de l'alcool`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile:</b>
     <br>Your skin presents
     blackheads on the T-zone, the pores are dilated on the T-zone and are tight on the
     rest of the face, you feel localized tightness, the complexion is dull on the cheeks
     and shiny on the T-zone, these are the signs of combination skin
     <b>My advice:</b><br><br>it is important to control excess sebum in oily areas without
     further desiccating drier areas. It is therefore advisable to favor
     non-greasy products and avoiding all products that may contain alcohol`
    }
}

export const LangageHair = {
    "hair_type": {
        "french": "Tes cheveux sont :",
        "english": "Your hair type:"
    },
    "steep": {
        "french": "Raides",
        "english": "Straight"
    },
    "corrugated": {
        "french": "Ondulés",
        "english": "Wavy"
    },
    "looped": {
        "french": "Bouclés",
        "english": "Curly"
    },
    "curly": {
        "french": "Frisés",
        "english": "Curled"
    },
    "frizzy": {
        "french": "Crépus",
        "english": "Frizzy"
    },
    "hair_thickness": {
        "french": "Tes cheveux sont :",
        "english": "Your hair thickness:"
    },
    "fins": {
        "french": "Fins",
        "english": "Thin"
    },
    "normals": {
        "french": "Normaux",
        "english": "Normals"
    },
    "thick": {
        "french": "Épais",
        "english": "Thick"
    },
    "scalp": {
        "french": "Ton cuir chevelu semble",
        "english": "Your scalp looks:"
    },
    "sec": {
        "french": "Sec",
        "english": "Dry"
    },
    "gras": {
        "french": "Gras",
        "english": "Greasy"
    },
    "short": {
        "french": "Courts",
        "english": "Short"
    },
    "normal": {
        "french": "Normal",
        "english": "Normal"
    },
    "long": {
        "french": "Longs",
        "english": "Long"
    },
    "mi_long": {
        "french": "Mi-longs",
        "english": "Medium-long"
    },
    "goals": {
        "french": "Quelle est la couleur de tes cheveux ?",
        "english": "What is your hair color?"
    },
    "volume": {
        "french": "Volume",
        "english": "Volume"
    },
    "smoothing": {
        "french": "Lissage",
        "english": "Smoothing"
    },
    "loops": {
        "french": "Boucles",
        "english": "Curls"
    },
    "shine": {
        "french": "Brillance",
        "english": "Shine"
    },
    "hair_care": {
        "french": "Quels sont vos objectifs de soins pour vos cheveux ?",
        "english": "What are your hair care goals?"
    },
    "growth": {
        "french": "Stimuler la croissance",
        "english": "Promote hair growth"
    },
    "strengthen": {
        "french": "Fortifier",
        "english": "Strengthen"
    },
    "appease": {
        "french": "Apaiser",
        "english": "Soothe"
    },
    "hydrate": {
        "french": "Hydrater",
        "english": "Hydrate"
    },
    "feed": {
        "french": "Nourrir",
        "english": "Nourish"
    },
    "repair": {
        "french": "Réparer",
        "english": "Repair"
    },
    "sebum": {
        "french": "Régulation du sébum",
        "english": "Sebum regulation"
    },
    "product_type": {
        "french": "Quel type de produit souhaitez-vous utiliser ?",
        "english": "What type of product would you like to use?"
    },
    "shampoo": {
        "french": "Shampoing",
        "english": "Shampoo"
    },
    "conditioner": {
        "french": "Après-shampoing",
        "english": "Conditioner"
    },
    "lotion": {
        "french": "Lotion",
        "english": "Lotion"
    },
    "spec_need": {
        "french": "Veuillez préciser si vous avez des besoins spécifiques ?",
        "english": "Please specify if you have any specific needs?"
    },
    "colored_hair": {
        "french": "Cheveux colorés",
        "english": "Colored hair"
    },
    "brief_type": {
        "french": "Type de cheveux : ",
        "english": "Hair type: "
    },
    "brief_thick": {
        "french": "Épaisseur des cheveux : ",
        "english": "Hair thickness: "
    },
    "brief_color": {
        "french": "Couleur des cheveux : ",
        "english": "My hair color: "
    },
    "brief_scalp": {
        "french": "Mon cuir chevelu est ",
        "english": "My scalp is "
    },
    "brief_beauty": {
        "french": "Beauté des cheveux : ",
        "english": "Hair beauty: "
    },
    "brief_soin": {
        "french": "Soin des cheveux : ",
        "english": "Hair care: "
    },
    "brief_expectation": {
        "french": "Attente particulière: ",
        "english": "Product expectation: "
    },
    "recommend_products": {
        "french": "Je connais précisément la nature de tes cheveux, je suis en train d'élaborer ta recommandation de produits.",
        "english": "I know precisely your hair type, I am working on your product recommendation."
    },
    "see_selection": {
        "french": "Voir ma sélection",
        "english": "See my selection"
    },
    "length": {
        "french": "Quelle est la longueur de tes cheveux ?",
        "english": "What is your hair length?"
    },
    "color": {
        "french": "Quelle est la couleur de tes cheveux ?",
        "english": "What is your hair color?"
    },
    "blond": {
        "french": "Blonds",
        "english": "Blond"
    },
    "light_blond": {
        "french": "Châtains clairs",
        "english": "Light blond"
    },
    "brown": {
        "french": "Brun",
        "english": "Brown"
    },
    "red": {
        "french": "Roux",
        "english": "Red"
    },
    "white_hair": {
        "french": "Blancs",
        "english": "White"
    },
    "colorful": {
        "french": "Colorés",
        "english": "Colorful"
    },
    "highlighted": {
        "french": "Méchés",
        "english": "Highlighted"
    },
    "irritated": {
        "french": "Irrité",
        "english": "Irritated"
    },
    "none": {
        "french": "Rien de cela",
        "english": "None"
    },
    "dandruff": {
        "french": "Es-tu sujet aux pellicules ?",
        "english": "Are you prone to dandruff?"
    },
    "yes": {
        "french": "Oui",
        "english": "Yes"
    },
    "no": {
        "french": "Non",
        "english": "No"
    },
    "have_dandruff": {
        "french": "J'ai des pellicules",
        "english": "I have dandruff"
    },
    "hair_loss": {
        "french": "Es-tu sujet à la chute de cheveux ?",
        "english": "Are you prone to hair loss?"
    },
    "have_hair_loss": {
        "french": "J'ai une chute de cheveux",
        "english": "I have hair loss"
    },
    "expectation": {
        "french": "As-tu une attente particulière sur tes produits ?",
        "english": "Do you have any specific expectations for your products?"
    },
    "bio": {
        "french": "Bio",
        "english": "Organic"
    },
    "naturals": {
        "french": "Naturel",
        "english": "Natural"
    },
    "made_FR": {
        "french": "Fabrication française",
        "english": "Made in France"
    },
    "others": {
        "french": "Autre",
        "english": "Other"
    },
    "lisse": {
        "french": "Les cheveux lisses sont caractérisés par une surface plane et uniforme. Ils ont tendance à avoir moins de frisottis et de boucles naturelles que les autres types de cheveux. Les cheveux lisses ont souvent un aspect brillant et réfléchissant la lumière, ce qui contribue à leur apparence saine et bien entretenue.",
        "english": "Straight hair is characterized by a flat and uniform surface. They tend to have fewer frizzes and natural curls than other hair types. Straight hair often has a shiny appearance that reflects light, contributing to a healthy and well-maintained look."
    },
    "ondule": {
        "french": "Les cheveux ondulés ont une texture intermédiaire entre les cheveux lisses et les cheveux bouclés. Ils présentent des ondulations naturelles et des boucles plus détendues. Les cheveux ondulés ont tendance à être plus secs, il est important de les hydrater régulièrement pour éviter les frisottis et les pointes fourchues. Utiliser des produits hydratants et des masques capillaires nourrissants peut aider à maintenir les cheveux ondulés en bonne santé.",
        "english": "Wavy hair has a texture that falls between straight and curly hair. It features natural waves and more relaxed curls. Wavy hair tends to be drier, so it's important to hydrate it regularly to prevent frizz and split ends. Using hydrating products and nourishing hair masks can help maintain the health of wavy hair."
    },
    "boucle": {
        "french": "Les cheveux bouclés ont tendance à être plus secs que les autres types de cheveux en raison de la structure de leurs boucles, ce qui rend plus difficile la distribution naturelle des huiles capillaires. Il est donc important de les hydrater régulièrement avec des produits adaptés pour maintenir leur santé et prévenir les frisottis.",
        "english": "Curly hair tends to be drier than other hair types due to the structure of its curls, which makes the natural distribution of scalp oils more challenging. Therefore, it's important to hydrate curly hair regularly with suitable products to maintain its health and prevent frizz."
    },
    "crepus": {
        "french": "Les cheveux crépus ont une structure délicate et sont plus sujets à la casse que les autres types de cheveux. Ils nécessitent donc une manipulation douce et des soins appropriés pour éviter les dommages. Les cheveux crépus ont tendance à être plus secs en raison de leur structure en spirale, ce qui rend difficile la répartition naturelle des huiles capillaires le long des mèches. Il est essentiel de les hydrater régulièrement avec des produits riches en hydratation pour maintenir leur santé et leur élasticité.",
        "english": "Curly hair has a delicate structure and is more prone to breakage than other hair types. Therefore, it requires gentle handling and proper care to avoid damage. Curly hair tends to be drier due to its spiral structure, which makes the natural distribution of scalp oils along the strands challenging. Regular hydration with moisture-rich products is essential to maintain the health and elasticity of curly hair."
    },
    "longs": {
        "french": "Les cheveux longs ont tendance à être plus sujets à la sécheresse et aux pointes fourchues. Utilisez des produits capillaires hydratants tels que des masques ou des huiles pour les cheveux afin de maintenir une hydratation optimale. Évitez également d'utiliser des outils chauffants tels que les fers à lisser ou les fers à friser de manière excessive pour prévenir les dommages causés par la chaleur.",
        "english": "Long hair tends to be more susceptible to dryness and split ends. Use hydrating hair products such as masks or hair oils to maintain optimal hydration. Also, avoid excessive use of heat-styling tools like straighteners or curling irons to prevent heat-induced damage."
    },
    "fin": {
        "french": "Les cheveux fins peuvent avoir tendance à devenir gras plus rapidement, il est donc préférable d'éviter de les laver tous les jours. Le lavage fréquent peut enlever les huiles naturelles nécessaires aux cheveux et les rendre encore plus plats. Essaye d'espacer les jours de lavage et utilise un shampooing sec pour rafraîchir les racines entre les lavages !",
        "english": "Fine hair may tend to become greasy more quickly, so it's best to avoid washing it every day. Frequent washing can strip the hair of its natural oils and make it even flatter. Try spacing out wash days and use dry shampoo to refresh the roots between washes!"
    },
    "epais": {
        "french": "Les cheveux épais ont tendance à être plus secs, donc l'hydratation est essentielle. Utilisez des masques capillaires riches et hydratants une à deux fois par semaine pour nourrir vos cheveux en profondeur. Vous pouvez également appliquer un sérum capillaire ou une huile légère sur les pointes pour sceller l'hydratation.",
        "english": "Thick hair tends to be drier, so hydration is essential. Use rich, hydrating hair masks once or twice a week to deeply nourish your hair. You can also apply a hair serum or lightweight oil to the ends to seal in moisture."
    },
    "meche": {
        "french": "Opte pour des shampooings, des revitalisants et des masques capillaires spécialement conçus pour les cheveux colorés ou méchés. Ces produits sont nourrissants et protecteurs pour aider à maintenir la couleur et à hydrater les cheveux.",
        "english": "Opt for shampoos, conditioners, and hair masks specially designed for colored or highlighted hair. These products are nourishing and protective to help maintain the color and hydrate the hair."
    },
    "decolore": {
        "french": "Les cheveux décolorés sont généralement plus sensibles et plus fragiles en raison du processus chimique de décoloration. Ils peuvent devenir plus poreux, plus cassants et plus sujets à la sécheresse. Les cheveux décolorés ont donc besoin d'une hydratation intense. Utilisez des masques capillaires riches et hydratants au moins une fois par semaine pour restaurer l'hydratation perdue. Vous pouvez également utiliser des huiles capillaires nourrissantes pour sceller l'hydratation et renforcer la douceur des cheveux décolorés.",
        "english": "Bleached hair is generally more sensitive and fragile due to the chemical bleaching process. It can become more porous, brittle, and prone to dryness. Bleached hair requires intense hydration. Use rich and hydrating hair masks at least once a week to restore lost moisture. You can also use nourishing hair oils to seal in hydration and enhance the softness of bleached hair."
    },
    "chute_cheveux": {
        "french": "La chute de cheveux est un problème courant qui peut affecter hommes et femmes. Elle peut être causée par différents facteurs tels que le stress, les changements hormonaux, une alimentation déséquilibrée, des carences nutritives, des problèmes de santé ou encore des facteurs génétiques. Un cuir chevelu sain favorise une bonne santé capillaire. Assurez-vous de garder votre cuir chevelu propre et bien hydraté.",
        "english": "Hair loss is a common issue that can affect both men and women. It can be caused by various factors such as stress, hormonal changes, unbalanced diet, nutritional deficiencies, health issues, or even genetic factors. A healthy scalp promotes good hair health. Make sure to keep your scalp clean and well-hydrated."
    },
    "hydratation": {
        "french": "Lorsque les cheveux ne reçoivent pas suffisamment d'hydratation, cela peut entraîner une perte de vitalité et de souplesse. N'hésitez pas à utiliser régulièrement des masques capillaires hydratants pour fournir une dose supplémentaire d'hydratation à vos cheveux !",
        "english": "When hair doesn't receive enough hydration, it can lead to a loss of vitality and flexibility. Don't hesitate to use hydrating hair masks regularly to provide an extra dose of hydration to your hair!"
    },
    "nutrition": {
        "french": "Lorsque les cheveux ne reçoivent pas suffisamment de nutriments essentiels, cela peut entraîner une détérioration de leur structure et de leur vitalité. Utilisez régulièrement des huiles capillaires nourrissantes pour hydrater et nourrir vos cheveux en profondeur !",
        "english": "When hair doesn't receive enough essential nutrients, it can lead to a deterioration of their structure and vitality. Use nourishing hair oils regularly to deeply hydrate and nourish your hair!"
    },
    "volumes": {
        "french": "Avant de sécher vos cheveux, utilisez des mousses ou des sprays volumisants sur les racines et les longueurs. Ces produits aident à soulever les cheveux à la racine et à créer une apparence plus volumineuse !",
        "english": "Before blow-drying your hair, use volumizing mousse or sprays on the roots and lengths. These products help lift the hair at the roots and create a more voluminous appearance!"
    },
    "soleil_uv": {
        "french": "Choisissez des produits capillaires spécifiquement formulés pour offrir une protection contre les rayons UV. Orientez-vous vers des sprays, des huiles capillaires ou des leave-in conditioners qui contiennent un filtre solaire pour protéger vos cheveux des rayons UV.",
        "english": "Choose hair products specifically formulated to provide protection against UV rays. Look for sprays, hair oils, or leave-in conditioners that contain sun protection filters to shield your hair from UV rays."
    },
    "frisottis": {
        "french": "L'humidité peut aggraver les frisottis, surtout par temps chaud et humide. Utilisez des produits capillaires anti-humidité ou appliquez une petite quantité de gel ou de crème coiffante pour créer une barrière protectrice contre l'humidité.",
        "english": "Humidity can worsen frizz, especially in warm and humid weather. Use anti-humidity hair products or apply a small amount of gel or styling cream to create a protective barrier against moisture."
    },
    "boucles": {
        "french": "Les cheveux bouclés sont naturellement plus sujets aux frisottis. Évitez de brosser vos cheveux quand ils sont secs, car cela peut casser les boucles et causer des frisottis. Utilisez plutôt un peigne à dents larges ou vos doigts pour démêler délicatement vos cheveux lorsque vous appliquez des produits coiffants ou lorsque vous les lavez.",
        "english": "Curly hair is naturally more prone to frizz. Avoid brushing your hair when it's dry, as it can break the curls and cause frizz. Instead, use a wide-tooth comb or your fingers to gently detangle your hair when applying styling products or washing it."
    },
    "brillances": {
        "french": "Lorsque vous rincez vos cheveux après le shampooing et le revitalisant, utilisez de l'eau froide ou tiède. L'eau froide aide à resserrer les cuticules des cheveux, ce qui les rend plus lisses et plus réfléchissants, améliorant ainsi leur brillance.",
        "english": "When rinsing your hair after shampooing and conditioning, use cold or lukewarm water. Cold water helps to close the hair cuticles, making them smoother and more reflective, thus enhancing their shine."
    },
    "protection_chaleurs": {
        "french": "Lorsque les cheveux sont exposés à des températures élevées, la chaleur peut pénétrer dans la cuticule du cheveu, ce qui peut entraîner une déshydratation, une perte de protéines et une fragilisation de la structure capillaire. L'utilisation d'une protection thermique crée une barrière entre les cheveux et la chaleur, réduisant ainsi les dommages potentiels.",
        "english": "When hair is exposed to high temperatures, heat can penetrate the hair cuticle, leading to dehydration, protein loss, and weakening of the hair structure. Using heat protection creates a barrier between the hair and the heat, reducing potential damage."
    },
    "chlore": {
        "french": "Avant de nager, appliquez un produit capillaire protecteur spécialement formulé pour protéger les cheveux du chlore. Ces produits forment une barrière protectrice entre les cheveux et le chlore.",
        "english": "Before swimming, apply a protective hair product specially formulated to shield your hair from chlorine. These products create a protective barrier between the hair and chlorine."
    },
    "pollution": {
        "french": "L'exposition prolongée à la pollution peut entraîner une perte de vitalité et d'éclat des cheveux. Les cheveux peuvent devenir ternes, manquer de brillance et avoir une apparence générale de mauvaise santé. Les cheveux bien hydratés et nourris sont mieux préparés pour faire face aux agressions de la pollution. Utilisez des masques capillaires hydratants et des huiles nourrissantes pour garder vos cheveux en bonne santé et les protéger des dommages.",
        "english": "Prolonged exposure to pollution can lead to a loss of vitality and luster in the hair. Hair can become dull, lack shine, and have an overall unhealthy appearance. Well-hydrated and nourished hair is better prepared to withstand the effects of pollution. Use hydrating hair masks and nourishing oils to keep your hair healthy and shield it from damage."
    },
    "sel_marrin": {
        "french": "Après une baignade dans la piscine ou dans l'eau de mer, rincez vos cheveux à l'eau claire pour éliminer les résidus de sel, qui peuvent causer des dommages aux cheveux.",
        "english": "After swimming in the pool or seawater, rinse your hair with clear water to remove salt residues, which can damage the hair."
    },
    "pellicule": {
        "french": "Lorsque vous utilisez le shampooing anti-pelliculaire, massez doucement votre cuir chevelu avec les bouts des doigts pendant quelques minutes. Cela aidera à exfolier les cellules mortes de la peau et à améliorer la circulation sanguine dans la région.",
        "english": "When using anti-dandruff shampoo, gently massage your scalp with your fingertips for a few minutes. This will help exfoliate dead skin cells and improve blood circulation in the area."
    },
    "take_final_selfie": {
        "french": "Pour finir prenons 2 selfies de votre chevelure. ",
        "english": "For ending take 2 selfies of your scalp."
    }
}
