import axios from 'axios'
/* import dotenv from 'dotenv';
dotenv.config(); */

const base_url = 'https://gpt-api.gydtech.io/'

export const getRoutine = async(data) => {
  
    try {
      const response = await axios({
        method: "post",
        url: `${base_url}api/gptAdviceHair/hairRoutine`,
        headers: { "Content-Type": "application/json",
            "x-api-key": "DnIX3Y7/C3pY+OX5vGJfdDmRYiN8kiCmJv22LA0mkR4=",
            "login": "CACTUS"},
        data
      });
      
      return response.data
    } catch (error) {
      console.error(error)
    }
  }