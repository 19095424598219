export const saveResWidget = (message, setState ) => {
    const save = {
        message: message,
        type: 'widget',
        id: new Date().getTime()
    }

    setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, save]
    }))
  }

